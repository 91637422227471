import {createRouter, createWebHashHistory} from 'vue-router';

// utils/updateFavicon.js
export function updateFavicon(iconUrl) {
  let link = document.querySelector("link[rel='icon']");

  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.head.appendChild(link);
  }

  link.href = iconUrl;
}

// 路由配置
const routes = [{path: '/', redirect: '/ldxy/puzzle'}, {
  path: '/ldxy/puzzle',
  name: '乱斗西游 2',
  component: () => import('@/views/ldxy/PuzzleQuestion/index.vue'),
  meta: {
    title: '乱斗西游 2',
    favicon: '/favicon.ico'
  },
}, {
  path: '/abo/series',
  name: '保卫萝卜',
  component: () => import('@/views/abo/CarrotSeries/index.vue'),
  meta: {
    title: '保卫萝卜',
    favicon: '/favicon2.ico'
  },
}, {
  path: '/abo/limited',
  name: '保卫萝卜发行',
  component: () => import('@/views/abo/CarrotLimited/index.vue'),
  meta: {title: '保卫萝卜限定系列'},
}, {
  path: '/abo/figure',
  name: '保卫萝卜单体',
  component: () => import('@/views/abo/CarrotFigure/index.vue'),
  meta: {title: '保卫萝卜限定系列'},
}, {
  path: '/abo/fantasy',
  name: '保卫萝卜家族',
  component: () => import('@/views/abo/CarrotFantasy/index.vue'),
  meta: {title: '保卫萝卜限定系列'},
}, {
  path: '/mxd/timeline',
  name: '冒险岛版本历史',
  component: () => import('@/views/mxd/Timeline/index.vue'),
  meta: {title: '冒险岛版本历史'},
}, {
  path: '/tools/mortgage',
  name: '房贷计算器',
  component: () => import('@/views/tools/Mortgage/index.vue'),
  meta: {title: '房贷计算器'},
}];

// 创建路由实例
const router = createRouter({
  history: createWebHashHistory(), routes,
});

// 全局路由守卫（可选）
router.beforeEach((to, from, next) => {
  // 动态设置页面标题
  document.title = to.meta.title || '默认标题';
  updateFavicon(to.meta.favicon || '/favicon.ico');
  next();
});

export default router;
