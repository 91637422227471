import {createApp} from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from './router';

const app = createApp(App);
app.use(ElementPlus)
app.use(router)

app.config.errorHandler = (err, instance, info) => {
  console.error('Vue Runtime Error:', err);
  console.warn('Error Info:', info);
};

app.mount('#app')
