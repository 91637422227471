<template>
  <div id="app">
    <!-- 顶部导航 -->
    <!--    <el-menu :default-active="activePath"-->
    <!--             mode="horizontal"-->
    <!--             router>-->
    <!--      <el-menu-item index="/ldxy/puzzle-question">乱斗西游2</el-menu-item>-->
    <!--      <el-menu-item index="/abo/carrot-series">保卫萝卜</el-menu-item>-->
    <!--      <el-menu-item index="/mxd/timeline">冒险岛</el-menu-item>-->
    <!--    </el-menu>-->
    <!-- 主内容区域 -->
    <router-view/>
  </div>
</template>

<script>
import {onMounted, ref} from 'vue';
import {useRoute} from 'vue-router';

import FooterInfo from "@/components/FooterInfo/index.vue";

export default {
  components: {FooterInfo},
  setup() {
    const route = useRoute();
    const activePath = ref(route.path);

    onMounted(() => {
      activePath.value = route.path;
    });

    return {
      activePath,
    };
  },
};
</script>

<style>
html, body {
  padding: 0px;
  margin: 0px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  margin: 20px;
}

</style>
