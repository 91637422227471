<template>
  <el-footer class="footer-info">
    <el-link href="https://beian.miit.gov.cn/" target="_blank" type="primary">
      ICP备案号：闽ICP备2023000187号
    </el-link>
  </el-footer>
</template>

<script>
export default {
  name: "FooterInfo",
};
</script>

<style scoped>
.footer-info {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  justify-content: center;
  background-color: var(--el-color-white);
  border-top: 1px solid var(--el-border-color);
  padding: 0;
  font-size: 14px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.05);
  display: flex; /* 使用 Flex 布局 */
  align-items: center; /* 垂直居中 */
  height: 40px;
}
</style>
